import { formatMoney as formatAsMoney } from "@shopify/theme-currency";

// const { money } = window.config;

// const { moneyFormat } = money;

const formatMoney = (price: string, format = "") =>
  formatAsMoney(price, format);

export default formatMoney;
